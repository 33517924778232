import React, {useEffect, useState} from 'react';
import {ReportsService} from "../../Orders/ReportsService";
import {useHistory, useRouteMatch} from "react-router-dom";
import {message, Spin} from "antd";
import "./ReportLab.css";

interface IReport {
    date: Date,
    orders: { [format: string]: { [imageName: string]: number } };
}

const sortImages = (a: [string, number], b: [string, number]) => {
    const imageNameA = a[0].split('.')[0];
    const imageNameB = b[0].split('.')[0];

    if (imageNameA > imageNameB) {
        return 1;
    }

    if (imageNameA < imageNameB) {
        return -1;
    }

    return 0;
}

const ReportLab = () => {
    const match = useRouteMatch();
    const id = (match.params as { id: string }).id;
    const history = useHistory();
    const [report, setReport] = useState<IReport | null>(null);
    
    useEffect(() => {
        (async () => {
            try {
                const { data } = await ReportsService.getSingle(id);
                setReport({
                    ...data,
                    orders: JSON.parse(data.orders),
                });
            } catch {
                history.push('/admin/dashboard');
                message.error("Nie udało się załadować raportu");
            }
        })();
    }, [history, id]);

    if (report === null) {
        return <Spin />
    }

    const renderSingleFormat = (format: string, images: { [imageName: string]: number }) => {
        return (
            <>
                <tr className="heading"><td className="table-format" colSpan={2}>{format}</td></tr>
                {
                    Object.entries(images)
                        .sort(sortImages)
                        .map(([ imageName, count ]) => <tr><td className="table-imageName">{imageName.split('/')[2]}</td><td className="table-count">{count} szt.</td></tr>)
                }
            </>
        );
    };

    return (
        <table className="report-table">
            <tbody>
            {
                Object.entries(report.orders).map(([ format, images ]) => renderSingleFormat(format, images))
            }

            </tbody>
        </table>
    );
};

export default ReportLab;