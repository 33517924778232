import React from 'react';
import {Button, Space} from "antd";
import {PriceListItem} from "../../../../Admin/Dashboard/Dashboard.types";
import "./PriceItem.scss";
import {formatPrice} from "../../../../shared/formatPrice";
import {RootState, useAppDispatch} from "../../../store/store";
import {addProductToCart, decreaseProductCount, removeProductFromCart} from "../../../store/cartSlice";
import {useSelector} from "react-redux";
import {DeleteOutlined} from "@ant-design/icons";

interface Props {
    priceItem: PriceListItem;
    source: string;
    removable?: boolean;
}

const PriceItem: React.FunctionComponent<Props> = ({ priceItem, source, removable }) => {
    const { cart: { cart } } = useSelector((state: RootState) => state);
    const dispatch = useAppDispatch();

    if (!cart) return null;

    return (
        <tr className="price-item-table-row">
            <td className="price-label-table-cell">{priceItem.format} <span className="price-item__price">({formatPrice(parseInt(priceItem.price))} zł)</span></td>
            <td>
                <Space>
                    <Button shape={"circle"} type={"dashed"} size={"small"} onClick={() => dispatch(decreaseProductCount({format: priceItem.format, source, cart}))}>-</Button>
                    <div>{cart[source]?.[priceItem.format] ?? 0}</div>
                    <Button shape={"circle"} type={"dashed"} size={"small"} onClick={() => dispatch(addProductToCart({ format: priceItem.format, source, cart }))}>+</Button>
                    {
                        removable && <Button shape={"circle"} type={"dashed"} danger size={"small"} onClick={() => dispatch(removeProductFromCart({ format: priceItem.format, source, cart }))}><DeleteOutlined /></Button>
                    }
                </Space>
            </td>
        </tr>
    );
};

export default PriceItem;