import { configureStore } from '@reduxjs/toolkit';
import {cartSlice} from "./cartSlice";
import {sessionSlice} from "./sessionSlice";
import {useDispatch} from "react-redux";

const store = configureStore({
    reducer: {
        cart: cartSlice.reducer,
        session: sessionSlice.reducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()

export default store;