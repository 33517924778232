import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {useEffect} from "react";

export const useSaveCartToLocalstorage = () => {
    const { cart: { cart }, session: { session } } = useSelector(( state: RootState ) => state);
    
    useEffect(() => {
        if (session && cart !== null) {
            localStorage.setItem("cart", JSON.stringify(cart));
        }
    }, [cart, session]);
}