import React from 'react';

const Regulamin = () => {
    return (
        <div>
                <p style={{textAlign: 'center'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>REGULAMIN</span></span></span></p>
                <p style={{textAlign: 'center'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>ŚWIADCZENIE USŁUG DROGĄ ELEKTRONICZNĄ</span></span></span></p>
                <p style={{textAlign: 'center'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>(w rozumieniu ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną)&nbsp;</span></span></span></p>
                <p style={{textAlign: 'center'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>§1</span></span></span></p>
                <p style={{textAlign: 'center'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>Pojęcia</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>Pojęcia używane w niniejszym Regulaminie mają znaczenia jak niżej:</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>1) Sprzedawca –”Fotografia” Piotr Świstak NIP 5271323261; REGON 013019877; al. Prymasa Tysiąclecia, nr 99, lok. 56; 01-424 Warszawa, dostarczający Towary&nbsp; (odbitki fotograficzne)&nbsp; za pośrednictwem Serwisu.</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>2) Użytkownik– usługobiorca, przedsiębiorca lub konsument, kupujący, każdy, kto korzysta z Towarów udostępnianych za pomocą Serwisu przez Sprzedawcę.</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>3) Serwis – system aplikacji internetowych stanowiących zbiór dokumentów statycznych i dynamicznych, zawierających pliki, w tym graficzne, skrypty oraz inne elementy zbioru połączone wzajemnymi relacjami udostępniony pod domeną&nbsp; https://wybierz.ovh</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>4) Usługi– wykonanie świadczenia bez jednoczesnej obecności Stron poprzez przekaz danych na indywidualne żądanie Użytkownika, przesyłanych i otrzymywanych za pomocą urządzeń do elektronicznego przetwarzania, i przechowywania danych, które są w całości wysyłane i odbierane&nbsp; lub transmitowane za pomocą sieci Internet.</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>5) Towary oznaczone co do gatunku lub co do tożsamości będące przedmiotem </span></span></span><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#2566a2'}}><span style={{backgroundColor: '#e6edf5'}}>dostawy</span></span></span></span><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}> na zamówienie Użytkownika przez Sprzedawcę w wykonaniu umowy zawartej bez jednoczesnej obecności Stron.</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>6) Potwierdzenie Realizacji- zawiadomienie Użytkownika&nbsp; przez Sprzedawcę o przyjęciu </span></span></span><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#2566a2'}}><span style={{backgroundColor: '#e6edf5'}}>oferty</span></span></span></span><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}> i realizacji zamówienia zawierające potwierdzenie treść oferty (umowy), w tym cenę, dane dotyczące&nbsp; Towaru&nbsp; oraz szczegóły dostawy.</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>7) Dostarczanie Produktów- sprzedaż Towarów lub świadczenie Usług.</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>8) Mail– adres poczty elektronicznej;</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>9) Strony- Sprzedawca i Użytkownik.</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>&nbsp;</span></span></span></p>
                <p style={{textAlign: 'center'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>§2</span></span></span></p>
                <p style={{textAlign: 'center'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>Postanowienia Ogólne</span></span></span></p>
                <ol>
                        <li style={{listStyleType: 'decimal'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>Właścicielem Serwisu jest Sprzedawca.</span></span></span></li>
                        <li style={{listStyleType: 'decimal'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>Regulamin określa rodzaje oraz warunki dostarczania&nbsp; Towarów za pomocą Serwisu przez&nbsp; Sprzedawcę oraz zasady korzystania z Serwisu, jak również warunki zawierania i rozwiązywania umów, a także tryb postępowania reklamacyjnego.</span></span></span></li>
                        <li style={{listStyleType: 'decimal'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>Regulamin jest dostępny w Serwisie w formie umożliwiającej jego pobranie, utrwalenie, zapisanie i wydrukowanie.</span></span></span></li>
                        <li style={{listStyleType: 'decimal'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>&nbsp;Jeżeli zajdzie konieczność zmiany Regulaminu, Sprzedawca udostępni w Serwisie jego nową wersję. Użytkownik, który nie akceptuje zmiany Regulaminu jest zobowiązany poinformować Sprzedawcę o braku akceptacji.&nbsp; Do Dostarczania Towarów, ma zastosowanie Regulamin w brzmieniu obowiązującym na dzień zawarcia umowy.</span></span></span></li>
                        <li style={{listStyleType: 'decimal'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>Zakazane jest dostarczanie przez Użytkownika treści innych niż określone Regulaminem, reklamowych lub o charakterze bezprawnym, a także podejmowanie działań mogących wywołać wadliwe funkcjonowanie Serwisu. W razie otrzymania przez Sprzedawcę zawiadomienia lub wiarygodnej informacji o bezprawnym charakterze danych dostarczanych przez Użytkownika, Sprzedawca może uniemożliwić dostęp do tych danych.</span></span></span></li>
                </ol>
                <p style={{textAlign: 'center'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>§3</span></span></span></p>
                <p style={{textAlign: 'center'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>Rodzaj i zakres działalności&nbsp;&nbsp;</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>Sprzedawca prowadzi działalność polegającą na dokumentacji fotograficznej (reportaż/zdjęcia portretowe) z takich wydarzeń jak: event / bale / studniówki itp. i na dostarczaniu (sprzedaży) Towarów (odbitki na papierze fotograficznym). Sprzedawca nie wykonuje działalności, która jest zabroniona prawnie.</span></span></span></p>
                <p style={{textAlign: 'center'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>§ 4</span></span></span></p>
                <p style={{textAlign: 'center'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>Zawarcie Umowy</span></span></span></p>
                <ol>
                        <li style={{listStyleType: 'decimal'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>Informacje zamieszczone w Serwisie nie stanowią </span></span></span><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#2566a2'}}><span style={{backgroundColor: '#e6edf5'}}>oferty</span></span></span></span><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}> zawarcia umowy dotyczącej oferowanych w nim Towarów, tylko zaproszenie do składania ofert</span></span></span></li>
                        <li style={{listStyleType: 'decimal'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>Przyjęcie zaproszenia do zawarcia umowy następuje poprzez złożenie zamówienia, o którym mowa w §5.</span></span></span></li>
                        <li style={{listStyleType: 'decimal'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>Przez prawidłowe złożenie zamówienia Użytkownik składa Sprzedawcy ofertę zawarcia umowy. Oferta wiąże Użytkownika do realizacji zamówienia w uzgodnionym wcześniej terminie.</span></span></span></li>
                        <li style={{listStyleType: 'decimal'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>Umowa dochodzi do skutku po cenach i na warunkach, które obowiązywały w chwili złożenia przez Użytkownika oferty, która została następnie przyjęta przez Sprzedawcę.</span></span></span></li>
                </ol>
                <p style={{textAlign: 'center'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>§5</span></span></span></p>
                <p style={{textAlign: 'center'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>Składanie Zamówienia</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>&nbsp;Składanie zamówienia następuje poprzez:&nbsp;&nbsp;</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>a) wybór ujęć fotograficznych oferowanych w Serwisie;</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>b) prawidłowe wypełnienie formularza zamówienia.</span></span></span></p>
                <p style={{textAlign: 'center'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>§6</span></span></span></p>
                <p style={{textAlign: 'center'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>Realizacja Zamówienia</span></span></span></p>
                <ol>
                        <li style={{listStyleType: 'decimal'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>Zamówienie zostanie zrealizowane w terminie wcześniej ustalonym. W przypadku przystąpienia do odbioru świadczenia online,&nbsp; zamówienia następuje niezwłocznie po złożeniu zamówienia.</span></span></span></li>
                        <li style={{listStyleType: 'decimal'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>Jeżeli nie można będzie zrealizować zamówienia w terminie określonym w niniejszym Regulaminie z uwagi na brak dostępności Towaru, Sprzedawca powiadomi o tym niezwłocznie Użytkownika telefonicznie lub za pomocą poczty elektronicznej. Jeżeli Użytkownik nie zaakceptuje zmienionego terminu realizacji zamówienia wszystko co do tej pory Strony świadczyły ulegnie zwrotowi w stanie niezmienionym i żadna ze Stron nie będzie podnosić wobec siebie jakichkolwiek roszczeń z tytułu braku realizacji zamówienia.</span></span></span></li>
                </ol>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p style={{textAlign: 'center'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>§7&nbsp;</span></span></span></p>
                <p style={{textAlign: 'center'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>&nbsp;Dostawa Towaru</span></span></span></p>
                <ol>
                        <li style={{listStyleType: 'decimal'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>1. Zamówione Towary (odbitki fotograficzne) zostaną dostarczone do Firmy / Szkoł y/ Przedszkola itp. z którą Sprzedawca ma umowę na wykonywanie usługi fotograficznej,</span></span></span></li>
                    <li style={{listStyleType: 'decimal'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>2. Użytkownik odbiera Towar (odbitki fotograficzne) bezpośrednio w: Firmie / Szkole / Przedszkolu itp.  z którym Sprzedawca ma umowę na wykonywanie usługi fotograficznej.</span></span></span></li>
                </ol>
                <p style={{textAlign: 'center'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>§ 8</span></span></span></p>
                <p style={{textAlign: 'center'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>Wymagania techniczne</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>1. Celem korzystania z Serwisu konieczne jest:</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>a)</span></span></span><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}> </span></span></span><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>połączenie z siecią Internet,</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>b)</span></span></span><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}> </span></span></span><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>przeglądarka internetowa</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>c)</span></span></span><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}> </span></span></span><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>włączona obsługa Cookies i Javascript</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>2. W razie korzystania przez Użytkownika ze sprzętu lub oprogramowania, które nie spełnia wymogów technicznych określonych w ust.1, Sprzedawca nie gwarantuje prawidłowości funkcjonowania Serwisu.</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>&nbsp;</span></span></span></p>
                <p style={{textAlign: 'center'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>§ 9</span></span></span></p>
                <p style={{textAlign: 'center'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>Polityka plików cookies</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>1. Sprzedawca zamieszcza pliki cookies na urządzeniu końcowym Użytkownika i uzyskuje do nich dostęp. Żadne inne dane nie są gromadzone przez Serwis automatycznie.</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>2. Cookies są danymi informatycznymi, plikami o niewielkim rozmiarze, które są zapisywane i przechowywane na urządzeniu końcowym Użytkownika podczas odwiedzania stron www. Dane te zawierają przeważnie nazwę strony internetowej, z której pochodzą, czas istnienia oraz przypadkowo wygenerowany, niepowtarzalny numer służący do identyfikacji przeglądarki, z jakiej&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; następuje połączenie ze stroną internetową.</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>3. Przeglądarka internetowa zasadniczo domyślnie umożliwia przechowywanie plików cookies w urządzeniu końcowym Użytkownika.&nbsp; W każdym czasie Użytkownik może dokonać zmiany ustawień plików cookies. Użytkownik może przede wszystkim zmienić ustawienia przeglądarki internetowej poprzez zablokowanie automatycznej obsługi plików cookies bądź poprzez ustawienia informowania o ich każdorazowym zamieszczeniu w urządzeniu Użytkownika. Szczegółowe informacje o możliwości i sposobach obsługi plików cookies dostępne są w ustawieniach przeglądarki.</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>4. Przez korzystanie z Serwisu, brak ustawień przeglądarki internetowej wyłączającej używanie plików cookies po zapoznaniu się z komunikatem o stosowaniu przez Serwis plików cookies wyrażana jest zgoda na instalację cookies na urządzeniu końcowym Użytkownika.</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>5. Jeżeli Użytkownik nie zgadza się na wykorzystywanie przez Sprzedawcę cookies, powinien zmienić ustawienia swojej przeglądarki, o których mowa w ust. 2 lub zrezygnować z&nbsp; korzystania z Serwisu.</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>Pliki cookies wykorzystywane są w celu:</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>a) dostosowania zawartości stron Serwisu do preferencji Użytkownika i optymalizacji korzystania ze stron internetowych; cookies pozwalają rozpoznać urządzenie Użytkownika i odpowiednio wyświetlić stronę internetową, dostosowaną do jego indywidualnych potrzeb</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>b) utrzymania sesji Użytkownika (po zalogowaniu), dzięki której Użytkownik nie musi na każdej podstronie Serwisu&nbsp; &nbsp; ponownie wpisywać loginu i hasła</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>6. W ramach serwisu możliwe jest zastosowanie następujących rodzajów plików cookies:</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>- user_access_token - token sesji użytkownika</span></span></span><br />
                        <span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>- cart - zawartość koszyka</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>7. Ustawienia przeglądarki powodujące wyłączenie cookies mogą spowodować utrudnienia w korzystaniu z niektórych usług w ramach Serwisu, w szczególności wymagających logowania.&nbsp;</span></span></span></p>
                <p style={{textAlign: 'center'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>§ 10</span></span></span></p>
                <p style={{textAlign: 'center'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>Płatności</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>1. Użytkownik jest zobowiązany do zapłaty za zamówiony Towar</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>2. Cena i inne istotne warunki związane z Towarem są każdorazowo podane w opisie&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; publikowanym na stronach Serwisu. Ceny zawierają wszystkie podatki i opłaty dodatkowe, Nie zawierają natomiast kosztów dostawy</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>3.&nbsp; Zapłata następuje w przy zamówieniu lub po dostarczeniu Towaru lub wystawieniu faktury w jeden ze sposobów wskazanych w ust. 5 niniejszego paragrafu.</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>4. Zapłata jest możliwa w formie:</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>a) gotówkowej/ przy odbiorze;</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>b) przelewem&nbsp; na konto</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>5. Podane w Serwisie kwoty są cenami brutto, co oznacza, że zawierają podatek od towarów i usług VAT.</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>6. Użytkownik&nbsp; wyraża zgodę i upoważnia Sprzedawcę do wystawienia faktury&nbsp; bez </span></span></span><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#2566a2'}}><span style={{backgroundColor: '#e6edf5'}}>podpisu</span></span></span></span><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}> odbiorcy.</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>7. Jeżeli Użytkownik zgłosi żądanie wystawienia faktury papierowej, to jest obowiązany podać żądane dane, niezbędne do&nbsp; wystawienia faktury.</span></span></span></p>
                <p style={{textAlign: 'justify'}}>&nbsp;</p>
                <p style={{textAlign: 'center'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>§11</span></span></span></p>
                <p style={{textAlign: 'center'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>Tryb postępowania reklamacyjnego</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>1. Użytkownik może składać reklamacje w sprawach dotyczących Towarów na piśmie:</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>− skan lub zdjęcie podpisanego oświadczenia woli przesłane mailem na adres:&nbsp; fotografie.rgb@gmail.com</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>2. Prawidłowo złożona reklamacja powinna zawierać co najmniej następujące dane:</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>− oznaczenie Użytkownika (w tym jego imię i nazwisko- jeśli były podane, adres pocztowy, adres e-mail, numer telefoniczny, a w przypadku osób prawnych nazwę, adres siedziby oraz dane kontaktowe osoby upoważnionej do załatwiania spraw związanych ze złożoną reklamacją);</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>− opis problemu będącego podstawą złożenia reklamacji.</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>3. Sprzedawca rozpatrzy reklamację w </span></span></span><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#2566a2'}}><span style={{backgroundColor: '#e6edf5'}}>terminie</span></span></span></span><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}> 14 dni od ich otrzymania i zawiadomi składającego o wyniku rozpatrzenia reklamacji.</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>4. W przypadku uwzględnienia </span></span></span><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#2566a2'}}><span style={{backgroundColor: '#e6edf5'}}>reklamacji</span></span></span></span><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>, Towar zostanie dostarczony bezpłatnie w stanie wolnym od wad. Jeżeli ponowne dostarczenie Towaru nie będzie możliwe Sprzedawca&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; zwróci równowartość ceny w terminie 7 dni. Ponowne dostarczenie Towaru nastąpi w&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; terminie 7 dni od dnia uwzględnienia reklamacji.</span></span></span></p>
                <p style={{textAlign: 'justify'}}>&nbsp;</p>
                <p style={{textAlign: 'justify'}}>&nbsp;</p>
                <p style={{textAlign: 'center'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>&nbsp;</span></span></span></p>
                <p style={{textAlign: 'center'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>§12</span></span></span></p>
                <p style={{textAlign: 'center'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>Polityka Prywatności</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>1. Administratorem danych osobowych w stosunku do informacji podawanych przez Użytkowników podczas korzystania z Serwisu.</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>2. Dane osobowe Sprzedawca będzie przetwarzać zgodnie z zasadami określonymi w Ustawie o ochronie danych osobowych, Ustawie o świadczeniu usług drogą elektroniczną w celach związanych z Dostarczaniem Towarów.</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>3. Użytkownik wyraża zgodę niniejszym i poprzez złożenie oświadczeń o wyrażeniu zgody na przetwarzanie danych w zakresie wskazanym w ust. 2 , co następuje poprzez zaznaczenie odpowiednich miejsc w formularzu zamówienia</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>4. Użytkownik może korzystać z Serwisu anonimowo lub z wykorzystaniem pseudonimu, chyba, że podanie określonych danych osobowych jest konieczne do Dostarczenia Towaru.</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>5. Zakres przetwarzanych danych obejmuje w szczególności następujące dane osobowe Użytkownika: imię i nazwisko, nazwę firmy w przypadku osób fizycznych prowadzących działalność gospodarczą oraz do celów rozliczeń, rachunkowych -Numer Identyfikacji Podatkowej, adres, w tym adres e-mail, telefon, fax.</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>6. Sprzedawca jest uprawniony do przetwarzania również innych niż te wskazane powyżej&nbsp; w ust. 3 danych osobowych Użytkowników, jeżeli są niezbędne do realizacji zamówienia. W takim przypadku dane te będą każdorazowo wyraźnie oznaczane jako dane niezbędne do wykonania zamówienia.</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>8. Jeżeli doszłoby do korzystania z Serwisu w sposób sprzeczny z prawem, Sprzedawca ma prawo do przetwarzania danych osobowych Użytkownika w zakresie niezbędnym do ustalenia jego odpowiedzialności. Sprzedawca powiadomi Użytkownika o niedozwolonych działaniach z żądaniem ich niezwłocznego zaprzestania oraz o przetwarzaniu danych osobowych w powyżej określonym celu.</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>9. Dane osobowe zgromadzone przez Sprzedawcę mogą być także udostępnione:</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>a) odpowiednim organom państwowym na ich żądanie na podstawie odpowiednich przepisów prawa,</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>b) innym osobom i podmiotom – w przypadkach przewidzianych przez przepisy prawa.</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>10. Sprzedawca zapewnia odpowiednie środki techniczne i organizacyjne zapewniające bezpieczeństwo danych osobowych udostępnionych przez Użytkowników, w szczególności uniemożliwiające dostęp do nich osobom trzecim lub ich przetwarzanie z naruszeniem przepisów prawa, zapobiegające utracie danych, ich uszkodzeniu lub zniszczeniu.</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>11. Użytkownikowi przysługuje prawo:</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>a) dostępu do swoich danych osobowych, prawo dokonywania uzupełnienia, korekt oraz aktualizacji treści danych w drodze komunikacji z Sprzedawcę;</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>b) żądania czasowego lub stałego wstrzymania przetwarzania danych osobowych lub ich usunięcia, o ile okazałyby się one niekompletne, nieaktualne, nieprawdziwe lub zebrane z naruszeniem przepisów prawa, w tym celu należy wysłać pismo, listem poleconym, na adres siedziby Sprzedawcą;</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>c) sprzeciwu wobec przetwarzania ich danych osobowych – w przypadkach przewidzianych przepisami prawa – oraz prawo żądania ich usunięcia, gdy staną się one zbędne do realizacji celu, dla którego zostały zebrane, w tym celu należy wysłać pismo, listem poleconym, na adres siedziby Sprzedawcy.</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>d) Po zakończeniu korzystania z Serwisu przez Użytkownika, Sprzedawca nie będzie przetwarzać danych osobowych wymienionych w ust. 6, za wyjątkiem tych danych, które są: dopuszczone do&nbsp; przetwarzania na podstawie przepisów prawa lub umowy, niezbędne do wyjaśnienia okoliczności niedozwolonego korzystania z usług. Sprzedawca usunie w takim przypadku wszelkie oznaczenia identyfikujące Użytkownika oraz zakończenie sieci telekomunikacyjnej i system teleinformatyczny, z którego korzystał Użytkownik, chyba że Użytkownik wyrazi zgodę na nieusuwanie tych oznaczeń.</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>e) Dane, o których mowa w ust. 8 przetwarzane będą tylko w zakresie niezbędnym dla zrealizowania zamówienia i po jej zakończeniu zostaną niezwłocznie usunięte przez Sprzedawcę.</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>&nbsp;</span></span></span></p>
                <p style={{textAlign: 'center'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>&nbsp;§13</span></span></span></p>
                <p style={{textAlign: 'center'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>Własność intelektualna</span></span></span></p>
                <ol>
                        <li style={{listStyleType: 'decimal'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>Sprzedawca oświadcza, że w związku lub wykonaniu świadczenia na rzecz Użytkownika realizacji zamówienia udostępnia Użytkownikowi treści chronione prawem własności intelektualnej, w szczególności utwory chronione prawem autorskim.</span></span></span></li>
                        <li style={{listStyleType: 'decimal'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>Użytkownik zobowiązany jest przestrzegać przepisów prawa własności intelektualnej. W szczególności wszelkie kopiowanie, wprowadzanie zmian, a także publiczne odtwarzanie udostępnianych treści bez pisemnej zgody , jest zakazane, o ile nie wynika to z odrębnych umów lub bezwzględnie obowiązujących przepisów prawa.</span></span></span></li>
                </ol>
                <p>&nbsp;</p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>&nbsp;</span></span></span></p>
                <p style={{textAlign: 'center'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>§ 14</span></span></span></p>
                <p style={{textAlign: 'center'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>Postanowienia końcowe</span></span></span></p>
                <ol>
                        <li style={{listStyleType: 'decimal'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>Sprzedawca ma prawo zablokować dostęp do Serwisu lub jego części z ważnych przyczyn, w tym w szczególności w razie stwierdzenia nieprawidłowości w korzystaniu z Serwisu, lub wystąpienia okoliczności, które mogłyby narazić na szkodę Użytkownika lub Sprzedawcę.</span></span></span></li>
                        <li style={{listStyleType: 'decimal'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>Użytkownik nie udziela gwarancji, że korzystanie z Serwisu będzie przebiegało niewadliwie.</span></span></span></li>
                        <li style={{listStyleType: 'decimal'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>W odniesieniu do informacji prezentowanych w ramach Serwisu, które zostały w nim zamieszczone lub dostarczone przez podmioty trzecie, Sprzedawca nie ponosi odpowiedzialności za aktualność, dokładność, lub kompletność tych informacji, jak również za ich przydatność dla jakichkolwiek działań Użytkownika.</span></span></span></li>
                        <li style={{listStyleType: 'decimal'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>Sprzedawca nie ponosi ponadto odpowiedzialności za korzystanie przez Użytkownika z Serwisu w sposób sprzeczny z postanowieniami Regulaminu, obowiązującymi przepisami prawa, lub postanowieniami umów obowiązujących pomiędzy Sprzedawcą a Użytkownikiem.</span></span></span></li>
                        <li style={{listStyleType: 'decimal'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>&nbsp;Za wszelkie skutki wynikłe z niewłaściwego wypełnienia przez Użytkownika formularzy dostępnych w Serwisie, w szczególności polegającego na podaniu przez Użytkownika błędnych lub nieprawdziwych danych, odpowiedzialność ponosi Użytkownik.</span></span></span></li>
                        <li style={{listStyleType: 'decimal'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>Postanowienia Regulaminu nie naruszają możliwości powoływania się przez Użytkowników będących konsumentami na bezwzględnie obowiązujące przepisy prawa regulujące ochronę praw konsumentów.</span></span></span></li>
                        <li style={{listStyleType: 'decimal'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>W sprawach nieuregulowanych w Regulaminie odpowiednie zastosowanie znajdują przepisy prawa polskiego, w tym ustawy z dnia 23 kwietnia 1964 r. – Kodeks cywilny oraz ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną, a także innych obowiązujących przepisów prawa.</span></span></span></li>
                        <li style={{listStyleType: 'decimal'}}><span style={{fontSize: '10.5pt'}}><span style={{fontFamily: 'Roboto,sans-serif'}}><span style={{color: '#141f2c'}}>Spory wynikłe na tle interpretacji lub wykonania Regulaminu będą rozstrzygane przez sąd właściwy według przepisów prawa.</span></span></span></li>
                </ol>
                <p>&nbsp;</p>
        </div>
    )
}

export default Regulamin;
