import { adminInstance } from "../helpers/fetch";

export class ReportsService {
    static generate(id: string) {
        return adminInstance.post(`${process.env.REACT_APP_API}/admin/sessions/${id}/reports`);
    }

    static getSingle(id: string) {
        return adminInstance.get(`${process.env.REACT_APP_API}/admin/reports/${id}`);
    }

    static getAll() {
        return adminInstance.get(`${process.env.REACT_APP_API}/admin/reports`);
    }
}
