import {AdditionalService, PriceListItem} from "../../Admin/Dashboard/Dashboard.types";

export interface Cart {
    [imageSource: string]: {
        [format: string]: number;
    }
}

export const calculatePriceForCart = (cart: Cart, priceList: PriceListItem[], additionalServices: AdditionalService[]) => {
    let priceTotal = 0;

    Object.entries(cart).forEach(([_, cartPositions]) => {
        Object.entries(cartPositions).forEach(([format, count]) => {
            if (count > 0) {
                const price = priceList.find(priceListItem => priceListItem.format === format)?.price;

                if (!price) throw Error('Price for the item not found!');

                priceTotal = priceTotal + (parseFloat(price) * count);
            }
        })
    });

    additionalServices.forEach(additionalService => priceTotal += additionalService.price)

    return priceTotal;
}
