import React, {useEffect, useState} from 'react';
import {ReportsService} from "../../Orders/ReportsService";
import {useHistory, useRouteMatch} from "react-router-dom";
import {message, Spin} from "antd";
import "./ReportPackaging.css";
import {formatPrice} from "../../../shared/formatPrice";

interface IReport {
    date: Date,
    orders: { [format: string]: { [imageName: string]: number } };
    rawOrders: {
        childName: string;
        parentName: string;
        groupName: string;
        cart: string;
        price: number;
        phoneNumber: string;
    }[];
    sessionName: string;
}

const ReportPackaging = () => {
    const match = useRouteMatch();
    const id = (match.params as { id: string }).id;
    const history = useHistory();
    const [report, setReport] = useState<IReport | null>(null);

    const showPhoneNumber = !!report?.rawOrders[0].phoneNumber;

    useEffect(() => {
        (async () => {
            try {
                const { data } = await ReportsService.getSingle(id);
                setReport({
                    ...data,
                    orders: JSON.parse(data.orders),
                    rawOrders: JSON.parse(data.rawOrders),
                });
            } catch {
                history.push('/admin/dashboard');
                message.error("Nie udało się załadować raportu");
            }
        })();
    }, [history, id]);

    if (report === null) {
        return <Spin />
    }

    const renderTableHeader = () => (
        <thead>
            <tr>
                <th>Opiekun</th>
                <th>Dziecko</th>
                <th>Zamówione zdjęcia</th>
                <th>Dodatkowe usługi</th>
                <th>Kwota</th>
                { showPhoneNumber ? <th>Kontakt</th> : <th>Podpis</th> }
            </tr>
        </thead>
    )

    const renderSinglePhotoOrder = (photoName: string, order: { [fornat: string]: number }) => {
        const imageName = photoName.split('/').slice(-1).pop();

        return Object.entries(order).map(([ format, count ]) => (
            <div className="singlePhotoItem">
                { format } - { imageName } - { count } szt.
            </div>
        ));
    }

    const renderOrderedPhotos = (cart: { [photoName: string]: { [formatName: string]: number } }) => {
        let cartAsArray = Object.entries(cart);

        const sortedCartAsArray = cartAsArray.sort((a, b) => {
            if (a[0] > b[0]) return 1;
            if (a[0] < b[0]) return -1;
            return 0;
        });

        return sortedCartAsArray.map(([ photoName, order ]) => {
            return renderSinglePhotoOrder(photoName, order)
        });
    }

    const renderTableBody = (rawOrder: any) => {
        return (
            <tr>
                <td>{ rawOrder.parentName }</td>
                <td>{ rawOrder.childName }</td>
                <td>{ renderOrderedPhotos(JSON.parse(rawOrder.cart)) }</td>
                <td>{ rawOrder.additionalServices.map((additionalService: any) => <span style={{ background: '#eee', padding: '2px 4px', margin: '0 3px', borderRadius: '20px' }}>{additionalService.label}</span>) }</td>
                <td>{ formatPrice(rawOrder.price) } zł</td>
                {
                    rawOrder.phoneNumber ? <td>{rawOrder.phoneNumber}</td> : <td className="signature" />
                }
            </tr>
        );
    }

    return (
        <>
            <h1>Nazwa sesji: {report.sessionName}</h1>
            <table className="report-table-packaging">
                { renderTableHeader() }
                <tbody>
                    {report.rawOrders.map(rawOrder => renderTableBody(rawOrder))}
                </tbody>
            </table>
        </>
    );
};

export default ReportPackaging;
