import React, {useState} from 'react';
import {Input, Space} from "antd";
import "./PriceInput.scss";
import {formatPrice} from "../../../shared/formatPrice";

interface Props {
    price: number;
    onChange: (newValue: number) => void;
}

const PriceInput: React.FunctionComponent<Props> = ({ price, onChange }) => {
    const [changedPrice, setChangedPrice] = useState<string>(formatPrice(price));
    const [hasError, setHasError] = useState<boolean>(false);

    const handleChange = () => {
        setHasError(false);

        let tempPrice = parseFloat(changedPrice.replace(',', '.')).toFixed(2);

        if (isNaN(parseInt(tempPrice))) {
            setHasError(true);
            return;
        }

        tempPrice = tempPrice.replace('.', '');

        onChange(parseInt(tempPrice));
        setChangedPrice(formatPrice(parseInt(tempPrice)))
    }

    return (
        <div>
            <Space>
                <Input
                    value={changedPrice}
                    onChange={(e) => setChangedPrice(e.currentTarget.value)}
                    onBlur={handleChange}
                    placeholder="Cena"
                    className={`price-item ${hasError ? "error" : ""}`}
                />
                <div>zł</div>
            </Space>
        </div>
    );
};

export default PriceInput;
