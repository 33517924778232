import React from 'react';
import {Tag} from "antd";

interface Props {
    paymentStatus: "paid" | "pending";
}

const PaymentStatus: React.FunctionComponent<Props> = ({ paymentStatus }) => {
    return (
        <div>
            {paymentStatus === "paid" ? <Tag color="green">Opłacone</Tag> : <Tag color="orange">Oczekujące</Tag>}
        </div>
    );
};

export default PaymentStatus;