import React, {useMemo} from 'react';
import {Divider} from "antd";
import './OrderExpandedRow.scss';
import {AdditionalService} from "../../Dashboard/Dashboard.types";

interface Props {
    cart: string;
    additionalServices: AdditionalService[];
}

const OrderExpandedRow: React.FunctionComponent<Props> = ({ cart, additionalServices }) => {
    const parsedCart: { [image: string]: { [format: string]: number } } = useMemo(() => JSON.parse(cart), [cart]);
    
    return (
        <div>
            {
                additionalServices.length !== 0 && (
                    <>
                        <h3>Zamówione usługi:</h3>
                        <ul>
                            {additionalServices.map(additionalService => <li>{additionalService.label}</li>)}
                        </ul>
                        <Divider />
                    </>
                )
            }

            <h3>Zamówione zdjęcia:</h3>
            {
                Object.entries(parsedCart).map(([ image, orderedFormats ]) => {
                    return (
                        <div>
                            <div className="image-name">{image.split('/').slice(-1)[0]}</div>
                            <div>{
                                Object.entries(orderedFormats).map(([ format, count ]) => (
                                    <div><span className="format">{format}:</span> {count}</div>
                                ))
                            }</div>
                            <Divider />
                        </div>
                    );
                })
            }
        </div>
    );
};

export default OrderExpandedRow;
