import {Cart} from "../../store/cart.helpers";

export const filterOnlyOrderedItems = (cart: Cart) => {
    let orderedItems: Cart = {};

    for (const image in cart) {
        for (const format in cart[image]) {
            const piecesOrdered = cart[image][format];

            if (piecesOrdered > 0) {
                orderedItems[image] = {
                    ...orderedItems[image],
                    [format]: piecesOrdered,
                }
            }
        }
    }

    return orderedItems;
}