import { userInstance } from "../../Admin/helpers/fetch";

export class PasswordService {
    static loginUser(password: string) {
        return userInstance.post(`${process.env.REACT_APP_API}/login`, { password });
    }

    static getSession(sessionId: string) {
        return userInstance.get(`${process.env.REACT_APP_API}/session/${sessionId}`);
    }
}
