import React from 'react';
import {Button, Col, Row} from "antd";
import "./ProductView.scss";
import Image from "../GridView/Image/Image";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";

interface Props {
    currentImageIndex: number;
    changeToGridView: () => void;
    changeImage: (direction: "next" | "prev") => void;
    sortedImages: string[];
}

const ProductView: React.FunctionComponent<Props> = ({ currentImageIndex, changeToGridView, changeImage, sortedImages }) => {
    const { session: { session } } = useSelector(( state: RootState  ) => state)

    if (!session) return null;

    return (
        <div className="content-wrapper single-view">
            <Row>
                <Col span={24}>
                    <Button onClick={changeToGridView} type={"link"} className="go-back-button">
                        &laquo; Wróc do albumu
                    </Button>
                </Col>
            </Row>
            <Image session={session} currentImageIndex={currentImageIndex} changeImage={changeImage} sortedImages={sortedImages} />
        </div>
    );
};

export default ProductView;