import React from 'react';
import {Button, Divider} from "antd";
import {useHistory} from "react-router-dom";
import "./SessionHeader.scss";

interface Props {
    pageName: string;
    showBackToAlbum?: boolean;
    title: string;
    message: string;
}

const SessionHeader: React.FunctionComponent<Props> = ({ pageName, showBackToAlbum, title, message }) => {
    const history = useHistory();

    const goBackToAlbum = () => {
        history.push("/session");
    }

    return (
        <div className="session-header-wrapper">
            <div className="page-name">{pageName}</div>
            <Divider dashed className="session-header__divider" />
            <div className="title">{title}</div>
            <div className="message">{message}</div>
            {showBackToAlbum && <Button onClick={goBackToAlbum} type="link" className="back-to-album-button">&laquo; Wróc do albumu</Button>}
        </div>
    );
};

export default SessionHeader;