import React from 'react';
import {Button} from "antd";
import CartWidget from "../../CartWidget/CartWidget";
import './Header.scss';
import {useHistory} from "react-router-dom";

interface Props {
    resetSession: () => void;
}

const Header: React.FunctionComponent<Props> = ({ resetSession }) => {
    const history = useHistory();

    const logout = () => {
        history.push('/');
        resetSession();
    }

    return (
        <div className="header">
            <Button type={"link"} onClick={logout} className="logout-button">Wyloguj</Button>
            <CartWidget />
        </div>
    );
};

export default Header;