import React, {useEffect, useState} from 'react';
import {ReportsService} from "../Orders/ReportsService";
import {Button, message, Space, Table} from "antd";
import {useHistory} from "react-router-dom";

const Reports = () => {
    const history = useHistory();
    const [reports, setReports] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                const { data } = await ReportsService.getAll();
                setReports(data);
            } catch {
                message.error("Nie udało się załadować raportów");
            }
        })();
    }, []);

    const columns = [
        {
            title: 'Data',
            dataIndex: 'date',
            key: 'date',
            render: (text: string) => new Intl.DateTimeFormat('pl', { dateStyle: "short", timeStyle: "medium" }).format(new Date(text))
        },
        {
            title: 'Nazwa sesji',
            dataIndex: 'sessionName',
            key: 'sessionName',
        },
        {
            title: '',
            dataIndex: '',
            key: 'edit',
            render: (text: string, report: { _id: string }) => (
                <Space>
                    <Button type="primary" onClick={() => history.push(`/admin/reports/lab/${report._id}`)}>Raport dla laboratorium</Button>
                    <Button type="primary" onClick={() => history.push(`/admin/reports/packaging/${report._id}`)}>Raport dla przedszkola</Button>
                </Space>
            )
        }
    ];

    return (
        <Table
            rowKey="_id"
            columns={columns}
            dataSource={reports}
        />
    );
};

export default Reports;