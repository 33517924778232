import React from 'react';
import {Col, Row} from "antd";
import Image from "../Image/Image";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";

interface Props {
    changeToSingleView: (index: number) => void;
    sortedImages: string[];
}

const GridView: React.FunctionComponent<Props> = ({ changeToSingleView, sortedImages }) => {
    const { session: { session } } = useSelector(( state: RootState ) => state);

    if (!session) return null;

    return (
        <div className="content-wrapper grid-view">
            <Row gutter={[32, 48]}>
                {sortedImages.sort().map((imageSrc, index) => {
                    return (
                        <Col span={6}>
                            <Image key={imageSrc} source={imageSrc} priceList={session.priceList} index={index} changeToSingleView={changeToSingleView} />
                        </Col>
                    )
                })}
            </Row>
        </div>
    );
};

export default GridView;