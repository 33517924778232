import React, {FormEvent, useEffect, useState} from 'react';
import {PasswordService} from "./Password.service";
import {useHistory} from "react-router-dom";
import jsCookie from 'js-cookie';

import './Password.scss';
import {Button, Checkbox, Divider, Input} from "antd";
import {RootState, useAppDispatch} from "../store/store";
import {useSelector} from "react-redux";
import {prepareSessionAndCart} from "../shared/useGetSession/useGetSession";

const Password: React.FunctionComponent = () => {
    const [password, setPassword] = useState<string>('');
    const [isError, setIsError] = useState<boolean>(false);
    const history = useHistory();
    const { session: { session } } = useSelector((state: RootState) => state);
    const dispatch = useAppDispatch();
    const [ regulamin, setRegulamin ] = useState<boolean>(false);
    const [ polityka, setPolityka ] = useState<boolean>(false);

    useEffect(() => {
        if (session) {
            history.push('/session');
        }
    }, [history, session]);

    const canLogin = regulamin && polityka;

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (!canLogin) return;

        setIsError(false);

        try {
            const { data: { user_access_token } } = await PasswordService.loginUser(password);
            jsCookie.set("user_access_token", user_access_token, { domain: process.env.REACT_APP_DOMAIN });
            localStorage.removeItem("cart");
            await prepareSessionAndCart(dispatch, user_access_token);
            history.push('/session')
        } catch (error) {
            setIsError(true);
        }
    }

    return (
        <div className="user-login-wrapper">
            <div className="login-card">
                <div>
                    <div className="login-card__title login-card__color">WPISZ HASŁO</div>
                    <div className="login-card__title">ABY OBEJRZEĆ GALERIĘ</div>
                </div>

                <Divider className="login-divider" />

                <form onSubmit={handleSubmit}>
                    <div className="login-form-wrapper">
                        <Input
                            size={"large"}
                            placeholder="Hasło"
                            className={`${isError ? 'error' : ''}`}
                            value={password}
                            onChange={e => {
                                setIsError(false);
                                setPassword(e.currentTarget.value);
                            }}
                            autoFocus
                        />

                        <div>
                            <Checkbox value={polityka} onChange={(e) => setPolityka(e.target.checked)}>Zapoznałem się z <a href="/polityka-prywatnosci" target="_blank">polityką prywatności</a></Checkbox>
                        </div>

                        <div>
                            <Checkbox value={regulamin} onChange={(e) => setRegulamin(e.target.checked)}>Zapoznałem się z <a href="/regulamin" target="_blank">regulaminem</a></Checkbox>
                        </div>

                        <Button onClick={handleSubmit} className="login-button" size={"large"} type={"primary"} disabled={!canLogin}>ZALOGUJ</Button>

                        {
                            isError && <div className="login-error">Nieprawidłowe hasło</div>
                        }
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Password;
