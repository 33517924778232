import React from 'react';
import {Button, Space} from "antd";
import {useHistory} from "react-router-dom";
import "./CartWidget.scss";
import {ShoppingCartOutlined} from '@ant-design/icons';
import {formatPrice} from "../../shared/formatPrice";
import {calculatePriceForCart} from "../store/cart.helpers";
import {useSelector} from "react-redux";
import {RootState} from "../store/store";

const CartWidget = () => {
    const history = useHistory();
    const { session: { session }, cart: { cart, additionalServices } } = useSelector(( state: RootState ) => state);

    if (!cart) return null;

    return (
        <div>
            <Space align={"center"}>
                <div className="cart-state"><ShoppingCartOutlined style={{ fontSize: '20px', marginRight: "2px" }} /> {formatPrice(calculatePriceForCart(cart, session!.priceList, additionalServices))} zł</div>
                <Button onClick={() => history.push('/cart')} className="go-to-cart-button" type={"link"}>Przejdź do koszyka</Button>
            </Space>
        </div>
    );
};

export default CartWidget;
