import React, {useEffect, useState} from 'react';
import {OrdersService} from "./Orders.service";
import {Button, message, Space, Switch, Table, Tag} from "antd";
import {IOrder} from "./Orders.types";
import {formatPrice} from "../../shared/formatPrice";
import {paymentMethods} from "../AdminSession/AdminSession";
import OrderExpandedRow from "./OrderExpandedRow/OrderExpandedRow";
import "./Orders.scss";

const Orders = () => {
    const [orders, setOrders] = useState<IOrder[]>([]);
    const [showOnlyOpen, setShowOnlyOpen] = useState<boolean>(true);
    const [showOnlyPaid, setShowOnlyPaid] = useState<boolean>(true);
    const [areOrdersLoading, setAreOrdersLoading] = useState<boolean>(false);

    useEffect(() => {
        fetchOrders();
    }, []);

    const fetchOrders = async () => {
        try {
            setAreOrdersLoading(true);
            const { data } = await OrdersService.getOrders();
            setOrders(data)
            setAreOrdersLoading(false);
        } catch {
            message.error("Wystąpił błąd");
        }
    }

    const closeOrder = async (orderId: string) => {
        try {
            await OrdersService.closeOrder(orderId);
            fetchOrders();
        } catch {
            message.error("Wystąpił błąd");
        }
    }

    const setAsPaid = async (orderId: string) => {
        try {
            await OrdersService.setAsPaid(orderId);
            fetchOrders();
        } catch {
            message.error("Wystąpił błąd");
        }
    }

    const columns = [
        {
            title: 'Nazwa sesji',
            dataIndex: 'sessionName',
            key: 'sessionName',
        },
        {
            title: 'Rodzic',
            dataIndex: 'parentName',
            key: 'parentName',
        },
        {
            title: 'Dziecko',
            dataIndex: 'childName',
            key: 'childName',
        },
        {
            title: 'Zakończone',
            dataIndex: 'isCompleted',
            key: 'isCompleted',
            render: (text: string, order: IOrder) => (
                <div>{order.isCompleted ? <Tag color="success">Tak</Tag> : <Tag color="error">Nie</Tag>}</div>
            )
        },
        {
            title: 'Zapłacone',
            dataIndex: 'paymentStatus',
            key: 'paymentStatus',
            render: (text: string, order: IOrder) => (
                <div>{order.paymentStatus === "paid" ? <Tag color="success">Tak</Tag> : <Tag color="error">Nie</Tag>}</div>
            )
        },
        {
            title: 'Sposób płatności',
            dataIndex: 'paymentMethod',
            key: 'paymentMethod',
            render: (paymentMethod: string) => (
                <div>{paymentMethod === paymentMethods.COD ? "Przy odbiorze" : "PayU"}</div>
            )
        },
        {
            title: 'Kwota',
            dataIndex: 'price',
            key: 'price',
            render: (price: string) => (
                <div>{formatPrice(parseInt(price))} zł</div>
            )
        },
        {
            title: '',
            dataIndex: '',
            key: 'edit',
            render: (text: string, order: IOrder) => (
                <Space size={"large"}>
                    <Button type={"primary"} disabled={order.isCompleted} danger onClick={() => closeOrder(order._id)}>Zakończ</Button>
                    <Button type={"default"} disabled={order.paymentStatus === "paid"} onClick={() => setAsPaid(order._id)}>Ustaw ręcznie jako opłacone</Button>
                </Space>
            )
        },
    ];

    const filteredData = () => {
        let filteredOrders: IOrder[] = [...orders];

        if (showOnlyOpen) {
            filteredOrders = filteredOrders.filter(order => !order.isCompleted);
        }

        if (showOnlyPaid) {
            filteredOrders = filteredOrders.filter(order => order.paymentStatus === "paid")
        }

        return filteredOrders.reverse();
    }

    return (
        <div>
            <div className="filters">
                <Space size={"large"}>
                    <div>Filtry:</div>
                    <div>Pokaż tylko otwarte: <Switch checked={showOnlyOpen} onChange={setShowOnlyOpen} /></div>
                    <div>Pokaż tylko opłacone: <Switch checked={showOnlyPaid} onChange={setShowOnlyPaid} /></div>
                </Space>
            </div>
            <Table
                dataSource={filteredData()}
                rowKey="_id"
                columns={columns}
                pagination={false}
                loading={areOrdersLoading}
                expandable={{
                    expandedRowRender: record => {
                        return <OrderExpandedRow cart={record.cart} additionalServices={record.additionalServices} />
                    },
                    rowExpandable: record => true,
                    expandIcon: ({ expanded, onExpand, record }) =>
                        expanded ? (
                            <Button type={"primary"} onClick={e => onExpand(record, e)}>Ukryj szczegóły</Button>
                        ) : (
                            <Button type={"primary"} onClick={e => onExpand(record, e)}>Pokaż szczegóły</Button>
                        ),
                    expandIconColumnIndex: columns.length - 1,
                }}
            />
        </div>
    );
};

export default Orders;
