import React from 'react';
import {PriceListItem} from "../../../Admin/Dashboard/Dashboard.types";
import {Card} from "antd";
import PriceItem from "./PriceItem/PriceItem";
import './Image.scss';
import {imageWithToken} from "../../../shared/imageWithToken";

interface Props {
    source: string;
    priceList: PriceListItem[];
    index: number;
    changeToSingleView: (index: number) => void;
}

const Image: React.FunctionComponent<Props> = ({ source, priceList, index, changeToSingleView }) => {
    const imageName = source.split('/').slice(-1).pop();

    return (
        <Card
            cover={<img src={imageWithToken(`${process.env.REACT_APP_API}/${source}`)} onClick={() => changeToSingleView(index)} className="image" onContextMenu={(e) => { e.preventDefault(); return false; }} />}
        >
            <div className="image__image-name">
                { imageName }
            </div>
            <table>
                <tbody>
                    {
                        priceList.map(priceItem => <PriceItem priceItem={priceItem} source={source} />)
                    }
                </tbody>
            </table>
        </Card>
    );
};

export default Image;
