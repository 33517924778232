import React, {useContext} from 'react';
import FullCart from "./FullCart/FullCart";
import './CartPage.scss';
import {Col, Row} from "antd";
import SessionHeader from "../shared/SessionHeader/SessionHeader";
import OrderDetails from "./OrderDetails/OrderDetails";
import {useSelector} from "react-redux";
import {RootState} from "../store/store";

const CartPage: React.FunctionComponent = () => {
    const { session: { session } } = useSelector((state: RootState) => state);

    if (!session) return null;

    return (
        <>
            <SessionHeader pageName="Twój koszyk" title={session.title} message={session.message} showBackToAlbum />
            <div className="content-wrapper">
                <Row>
                    <Col span={14}>
                        <FullCart />
                    </Col>
                    <Col span={5} offset={5}>
                        <OrderDetails />
                    </Col>
                </Row>
            </div>
        </>
    )
};

export default CartPage;