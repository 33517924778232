import { adminInstance } from "../helpers/fetch";

export class OrdersService {
    static getOrders() {
        return adminInstance.get(`${process.env.REACT_APP_API}/admin/orders`);
    }

    static closeOrder(orderId: string) {
        return adminInstance.put(`${process.env.REACT_APP_API}/admin/orders/close/${orderId}`);
    }

    static setAsPaid(orderId: string) {
        return adminInstance.put(`${process.env.REACT_APP_API}/admin/orders/setAsPaid/${orderId}`);
    }

    static getSingleOrder(orderId: string) {
        return adminInstance.get(`${process.env.REACT_APP_API}/admin/orders/${orderId}`);
    }
}
