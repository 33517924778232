import React, {useState} from 'react';
import {Button, Card, Checkbox, Divider, Input, Select} from "antd";
import {Field, Form} from 'react-final-form'
import {formatPrice} from "../../../shared/formatPrice";
import {OrderDetailsService} from "./OrderDetails.service";
import {filterOnlyOrderedItems} from "./helpers";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../store/store";
import {calculatePriceForCart} from "../../store/cart.helpers";
import {toggleAdditionalService} from "../../store/cartSlice";
import {paymentMethods} from "../../../Admin/AdminSession/AdminSession";

const OrderDetails = () => {
    const { cart: { cart, additionalServices }, session: { session } } = useSelector((state: RootState) => state);
    const dispatch = useAppDispatch();

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);

    const history = useHistory();

    const onSubmit = async (formData: any) => {
        setHasError(false);
        setIsSubmitting(true);

        const order = {
            ...formData,
            sessionId: session!._id,
            cart: filterOnlyOrderedItems(cart!),
            additionalServices,
        };

        try {
            const { data } = await OrderDetailsService.placeOrder(order);

            if (data._id) {
                if (data.paymentMethod === paymentMethods.PAYU) {
                    window.location.href = data.paymentLink;
                } else {
                    history.push(`/order/${data._id}`);
                }
            }
        } catch {
            setHasError(true);
            setIsSubmitting(false);
        }
    }

    const validate = (formData: any) => {
        const errors: { [key: string]: string } = {};

        if (!formData.parentName) errors.parentName = "Pole wymagane";
        if (!formData.childName) errors.childName = "Pole wymagane";

        if (session?.showGroupPicker) {
            if (!formData.groupName) errors.groupName = "Pole wymagane";
        }

        if (session?.showPhoneInput) {
            if (!formData.phoneNumber) errors.phoneNumber = "Pole wymagane";
        }

        return errors;
    }

    if (!cart) return null;

    return (
        <Card>
            <Form
                onSubmit={onSubmit}
                validate={validate}
                render={({ handleSubmit, valid }) => (
                    <form onSubmit={handleSubmit}>
                        <div>
                            Wartość koszyka: {formatPrice(calculatePriceForCart(cart, session!.priceList, additionalServices))} zł
                        </div>

                        <div>
                            <label htmlFor="parent-name">Imię i nazwisko zamawiającego:</label>
                            <Field name="parentName" render={({ input }) => (
                                <Input id="parent-name" placeholder="Imię i nazwisko" {...input} />
                            )} />
                        </div>
                        <Divider />
                        <div>
                            <label htmlFor="child-name">Imię i nazwisko dziecka:</label>
                            <Field name="childName" render={({ input }) => (
                                <Input id="child-name" placeholder="Imię i nazwisko dziecka" {...input} />
                            )} />
                        </div>

                        {
                            session && session.showGroupPicker && (
                                <>
                                    <Divider  />
                                    <label htmlFor="group-name">Nazwa grupy:</label>
                                    <Field name="groupName" render={({ input }) => (
                                        <Select onChange={input.onChange} value={input.value} style={{ width: "100%" }}>
                                            {
                                                session?.groups.map(({ name }) =>
                                                    <Select.Option value={name}>{name}</Select.Option>
                                                )
                                            }

                                        </Select>
                                    )} />
                                </>
                            )
                        }

                        {
                            session && session.showPhoneInput && (
                                <>
                                    <Divider  />
                                    <label htmlFor="phone-number">Numer telefonu / adres e-mail:</label>
                                    <Field name="phoneNumber" render={({ input }) => (
                                        <Input id="phone-number" placeholder="Numer telefonu / adres e-mail" {...input} />
                                    )} />
                                </>
                            )
                        }

                        <Divider />

                        {
                            session && session.additionalServices.map(additionalService => (
                                <div>
                                    <Field name={additionalService.label} type="checkbox" render={({ input }) => {
                                        return (
                                            <Checkbox
                                                onChange={(e) => {
                                                    input.onChange(e);
                                                    dispatch(toggleAdditionalService({ additionalService, isNew: !input.checked }))
                                                }}
                                                checked={input.checked}
                                                style={{ "marginRight": "16px" }}
                                            />
                                        )
                                    }} />
                                    {additionalService.label} (+ {formatPrice(additionalService.price)} zł)
                                </div>
                            ))
                        }

                        <Divider />

                        <div>
                            <Button type={"primary"} size={"large"} onClick={handleSubmit} disabled={!valid || isSubmitting}>
                                {session?.paymentMethod === paymentMethods.PAYU ? "ZAMÓW I ZAPŁAĆ" : "ZAMÓW"}
                            </Button>
                        </div>
                        <div>
                            { hasError && <div className="error">Wystąpił błąd</div> }
                        </div>
                    </form>
                )}
            />
        </Card>
    );
};

export default OrderDetails;
