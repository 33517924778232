import jsCookie from "js-cookie";

export const imageWithToken = (path: string): string => {
    let token = undefined;

    if (jsCookie.get('access_token')) {
        token = jsCookie.get('access_token')
    } else if (jsCookie.get('user_access_token')) {
        token = jsCookie.get('user_access_token')
    }

    return `${path}?token=${token}`
}
