import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Cart} from "./cart.helpers";
import {AdditionalService, ISession} from "../../Admin/Dashboard/Dashboard.types";

interface CartState {
    cart: Cart | null;
    additionalServices: AdditionalService[];
}

const initialState: CartState = {
    cart: null,
    additionalServices: [],
}

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        setInitialCart: (state, action: PayloadAction<{ session: ISession }>) => {
            const newSessionCart: Cart = {};

            action.payload.session.images.forEach(imageSource => {
                Object.defineProperty(newSessionCart, imageSource, { value: {}, enumerable: true });

                action.payload.session.priceList.forEach(priceListItem => {
                    newSessionCart[imageSource][priceListItem.format] = 0;
                });
            });

            state.cart = newSessionCart;
        },
        restoreCart: (state, action: PayloadAction<{ cart: Cart | null }>) => {
            state.cart = action.payload.cart;
        },
        addProductToCart: (state, { payload: { cart, format, source } }: PayloadAction<{ format: string, source: string, cart: Cart }>) => {
            const currentCount = cart[source][format];

            const newCart = {
                ...cart,
                [source]: {
                    ...cart[source],
                    [format]: currentCount + 1,
                }
            };

            state.cart = newCart;
        },
        decreaseProductCount: (state, { payload: { cart, format, source } }: PayloadAction<{ format: string, source: string, cart: Cart }>) => {
            const currentCount = cart[source][format];

            const newCart = {
                ...cart,
                [source]: {
                    ...cart[source],
                    [format]: currentCount - 1,
                }
            };

            state.cart = newCart;
        },
        removeProductFromCart: (state, { payload: { cart, format, source } }: PayloadAction<{ format: string, source: string, cart: Cart }>) => {
            const currentCount = cart[source][format];

            if (currentCount === 0) return;

            const newCart = {
                ...cart,
                [source]: {
                    ...cart[source],
                    [format]: 0,
                }
            };

            state.cart = newCart;
        },
        toggleAdditionalService: (state, { payload: { additionalService, isNew } }: PayloadAction<{ additionalService: AdditionalService, isNew: boolean }>) => {
            if (isNew) {
                state.additionalServices = [...state.additionalServices, additionalService]
            } else {
                state.additionalServices = state.additionalServices.filter(service => service.label !== additionalService.label)
            }
        }
    },
})

export const { setInitialCart, addProductToCart, decreaseProductCount, removeProductFromCart, restoreCart, toggleAdditionalService } = cartSlice.actions;

export default cartSlice.reducer;
