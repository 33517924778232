import React from 'react';
import {useHistory} from "react-router-dom";
import {Card, Divider} from "antd";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import PriceItem from "../../Session/Image/PriceItem/PriceItem";

import "./FullCart.scss";
import {imageWithToken} from "../../../shared/imageWithToken";

const sortAlphabetically = (a: [string, unknown], b: [string, unknown]) => {
    const [ aName ] = a;
    const [ bName ] = b;

    if (aName > bName) {
        return 1;
    } else if (bName > aName) {
        return -1;
    } else {
        return 0;
    }
}

const FullCart = () => {
    const { cart: { cart }, session: { session } } = useSelector((state: RootState) => state);
    const history = useHistory();

    if (!cart) return null;

    return (
        <Card>
            <h4>Zdjęcia w koszyku</h4>
            <Divider dashed />
            {
                Object.entries(cart).sort(sortAlphabetically).map(([imageSrc, cartPositions]) => {
                    let isInCart = false;
                    let renderedPositions: React.ReactNode[] = [];

                    Object.entries(cartPositions).map(([format, count]) => {
                        if (count > 0) {
                            isInCart = true;

                            const price = session!.priceList.find(priceListItem => priceListItem.format === format)?.price;

                            if (!price) throw Error('Price for the item not found!');

                            renderedPositions.push(
                                <PriceItem priceItem={{ price, format }} source={imageSrc} removable />
                            );
                        }
                    })

                    if (isInCart) {
                        const imageName = imageSrc.split('/').slice(-1).pop();

                        return (
                            <div className="fullCart_productItem">
                                <div className="fullCart_image"><img src={imageWithToken(`${process.env.REACT_APP_API}/${imageSrc}`)} style={{ maxWidth: '100%', maxHeight: '100%' }} /></div>
                                <div className="fullCart_imageName">{ imageName }</div>
                                <div className="fullCart_priceItems">
                                    {renderedPositions}
                                </div>
                            </div>
                        );
                    }
                })
            }
        </Card>
    );
};

export default FullCart;
