import React from 'react';
import {Button, Col, Row} from "antd";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import PriceItem from "../../Image/PriceItem/PriceItem";
import {ISession} from "../../../../Admin/Dashboard/Dashboard.types";
import "./Image.scss";
import {imageWithToken} from "../../../../shared/imageWithToken";

interface Props {
    session: ISession;
    currentImageIndex: number;
    changeImage: (direction: "next" | "prev") => void;
    sortedImages: string[];
}

const Image: React.FunctionComponent<Props> = ({ changeImage, session, currentImageIndex, sortedImages }) => {
    return (
        <>
            <Row>
                <Col span={1}>
                    <div className="single-product-nav">
                        <Button type={"link"} onClick={() => changeImage("prev")}>
                            <LeftOutlined />
                        </Button>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="image-wrapper">
                        <img src={imageWithToken(`${process.env.REACT_APP_API}/${sortedImages[currentImageIndex]}`)} onContextMenu={(e) => { e.preventDefault(); return false; }} />
                    </div>
                </Col>
                <Col span={1}>
                    <div className="single-product-nav">
                        <Button type={"link"} onClick={() => changeImage("next")}>
                            <RightOutlined />
                        </Button>
                    </div>
                </Col>
                <Col span={9} offset={1}>
                    <div className="price-list-table">
                        <table>
                            {
                                session.priceList.map(priceItem => <PriceItem priceItem={priceItem} source={sortedImages[currentImageIndex]} />)
                            }
                        </table>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default Image;
