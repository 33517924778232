import React, {useState} from 'react';

import SessionHeader from "../shared/SessionHeader/SessionHeader";
import GridView from "./GridView/GridView";
import ProductView from "./ProductView/ProductView";
import {useSelector} from "react-redux";
import {RootState} from "../store/store";

enum GalleryMode {
    GRID = "grid",
    SINGLE = "single",
}

const Session: React.FunctionComponent = () => {
    const { session: { session } } = useSelector(( state: RootState ) => state);

    const [galleryMode, setGalleryMode] = useState<GalleryMode>(GalleryMode.GRID);
    const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);

    if (session === null) {
        return null;
    }

    const sortedImages = [...session.images].sort();

    const changeImage = (direction: "next" | "prev") => {
        if (direction === "next") {
            setCurrentImageIndex(prevCurrentImageIndex => {
                if (prevCurrentImageIndex === sortedImages.length -1) {
                    return 0;
                }

                return ++prevCurrentImageIndex
            });
        } else {
            setCurrentImageIndex(prevCurrentImageIndex => {
                if (prevCurrentImageIndex === 0) {
                    return sortedImages.length - 1;
                }

                return --prevCurrentImageIndex
            });
        }
    }

    const changeToSingleView = (index: number) => {
        setCurrentImageIndex(index);
        setGalleryMode(GalleryMode.SINGLE);
    }

    return (
        <div className="main-content">
            {
                galleryMode === GalleryMode.GRID && (
                    <>
                        <SessionHeader pageName="Album zdjęć" title={session.title} message={session.message} />
                        <GridView changeToSingleView={changeToSingleView} sortedImages={sortedImages} />
                    </>
                )
            }

            {
                galleryMode === GalleryMode.SINGLE && (
                    <ProductView
                        currentImageIndex={currentImageIndex}
                        changeToGridView={() => setGalleryMode(GalleryMode.GRID)}
                        changeImage={changeImage}
                        sortedImages={sortedImages}
                    />
                )
            }
        </div>
    );
};

export default Session;