import React, {useEffect, useRef, useState} from 'react';
import {useRouteMatch} from "react-router-dom";
import {OrderService} from "./Order.service";
import {message} from "antd";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../store/store";
import {setInitialCart} from "../store/cartSlice";
import {paymentMethods} from "../../Admin/AdminSession/AdminSession";
import PaymentStatus from './PaymentStatus/PaymentStatus';

import "./Order.scss";

const Order: React.FunctionComponent = () => {
    const match = useRouteMatch();
    const orderId = (match.params as { id: string }).id;
    const { session: { session } } = useSelector(( state: RootState ) => state);
    const dispatch = useAppDispatch();
    const [order, setOrder] = useState<any>(null);

    useEffect(() => {
        if (session) {
            dispatch(setInitialCart({ session }));
        }
    }, [dispatch, session]);

    const getOrderData = async () => {
        try {
            const { data } = await OrderService.getOrder(orderId);
            setOrder(data);
        } catch {
            message.error("Nie udało się pobrać danych zamówienia!");
        }
    }
    
    useEffect(() => {
        getOrderData();
    }, [orderId]);
    
    useEffect(() => {
        if (order && order.paymentStatus === "pending") {
            setTimeout(() => {
                getOrderData();
            }, 1000 * 10);
        }
    }, [order]);
    
    return (
        <div className="order-placed-wrapper">
            <h2>Dziękujemy za złożenie zamówienia.</h2>

            {order && order.paymentMethod === paymentMethods.PAYU && (
              <>
                  Status:
                  {
                      <PaymentStatus paymentStatus={order.paymentStatus} />
                  }
                  {
                      order.paymentStatus !== "paid" && (
                          <>
                            <br /><br />
                            Możesz wrócić na tę stronę później lub poczekać na aktualizację statusu.
                          </>
                      )
                  }

              </>
            )}
        </div>
    );
};

export default Order;