import React from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Login from "./Admin/Login/Login";
import Dashboard from "./Admin/Dashboard/Dashboard";
import AdminSession from "./Admin/AdminSession/AdminSession";
import UserRouter from "./User/UserRouter";
import ScrollToTop from "./shared/ScrollToTop/ScrollToTop";
import { ConfigProvider } from 'antd';
import plPL from 'antd/lib/locale/pl_PL';

import 'antd/dist/antd.css';
import Orders from "./Admin/Orders/Orders";
import ReportLab from "./Admin/Reports/ReportLab/ReportLab";
import {Provider} from "react-redux";
import store from "./User/store/store";
import ReportPackaging from "./Admin/Reports/ReportPackaging/ReportPackaging";

function App() {
  return (
    <ConfigProvider locale={plPL}>
        <BrowserRouter>
          <ScrollToTop />
          <Switch>

          <Route path="/admin/dashboard"><Dashboard /></Route>
          <Route path="/admin/session/:id"><AdminSession /></Route>
          <Route path="/admin/orders" exact><Orders /></Route>
          <Route path="/admin/reports/lab/:id"><ReportLab /></Route>
          <Route path="/admin/reports/packaging/:id"><ReportPackaging /></Route>
          <Route path="/admin/login"><Login /></Route>

            <div className="user">
              <Provider store={store}>
                <UserRouter />
              </Provider>
            </div>
          </Switch>
        </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
