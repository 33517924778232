import React, {useEffect, useState} from 'react';
import {DashboardService} from "./Dashboard.service";
import {useHistory} from "react-router-dom";
import {ISession} from "./Dashboard.types";
import {AdminSessionService} from "../AdminSession/AdminSession.service";
import {Button, message, Popconfirm, Space, Table, Tabs} from "antd";
import StickyMenu from "../shared/StickyMenu/StickyMenu";
import Orders from "../Orders/Orders";
import Reports from "../Reports/Reports";
import "./Dashboard.scss";
import {ReportsService} from "../Orders/ReportsService";

const { TabPane } = Tabs;

const Dashboard = () => {
    const history = useHistory();
    const [sessions, setSessions] = useState<ISession[]>([]);
    const [areSessionsLoading, setAreSessionsLoading] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            try {
                setAreSessionsLoading(true);
                const { data } = await DashboardService.getAllSessions();
                setSessions(data);
                setAreSessionsLoading(false);
            } catch (e: any) {
                if (e.response.status === 401) {
                    history.push('/admin/login');
                }
            }
        })();
    }, [history]);

    const editSession = (id: string) => {
        history.push(`/admin/session/${id}`);
    }

    const generateReportAndDeleteSession = async (id: string) => {
        try {
            const { data: report } = await ReportsService.generate(id);

            if (!report.reportId) {
                throw new Error("Generating report failed");
            }

            await AdminSessionService.deleteSession(id);
            setSessions(sessions.filter(session => session._id !== id));
        } catch (e: any) {
            if (e.response.status === 401) {
                history.push('/admin/login');
            } else {
                message.error("Nie udało się wygenerować raportu, sesja NIE została usunięta");
            }
        }
    }

    const columns = [
        {
            title: 'Nazwa albumu',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Hasło',
            dataIndex: 'password',
            key: 'password',
        },
        {
            title: '',
            dataIndex: '',
            key: 'edit',
            render: (text: string, session: ISession) => (
                <Space size={"large"}>
                    <Button type={"primary"} onClick={() => editSession(session._id)}>Edytuj</Button>
                    <Popconfirm title="Czy jesteś pewny, że chcesz usunąć ten album?" okText="Tak" cancelText="Nie" onConfirm={() => generateReportAndDeleteSession(session._id)}>
                        <Button type={"primary"} danger>Wygeneruj zamówienie dla zakładu i usuń</Button>
                    </Popconfirm>
                </Space>
            )
        },
    ];

    return (
        <div className="admin">
            <StickyMenu />

            <div className="content">

                <Tabs>
                    <TabPane tab="Albumy" key="1">
                        <Button type={"primary"} onClick={() => editSession("new")} style={{ marginBottom: '24px' }}>Dodaj nowy album</Button>

                        <Table rowKey="_id" columns={columns} dataSource={sessions} pagination={false} loading={areSessionsLoading} />
                    </TabPane>
                    <TabPane tab="Zamówienia" key="2">
                        <Orders />
                    </TabPane>
                    <TabPane tab="Raporty" key="3">
                        <Reports />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    );
};

export default Dashboard;
