import React from 'react';

const Polityka = () => {
    return (
        <>
            <div>
                <p style={{textAlign: 'center'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Arial'}}><span style={{color: '#000000'}}><strong>Polityka prywatności&nbsp;</strong></span></span></span></p>
                <p>&nbsp;</p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Arial'}}><span style={{color: '#000000'}}>Polityka prywatności serwisu www.wybierz.ovh</span></span></span></p>
                <ol>
                    <li style={{listStyleType: 'decimal'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Arial'}}><span style={{color: '#000000'}}>Informacje ogólne&nbsp;</span></span></span></li>
                </ol>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Arial'}}><span style={{color: '#000000'}}>Niniejsza Polityka Prywatności określa zasady przetwarzania i ochrony danych osobowych przekazanych przez Użytkowników w związku z korzystaniem przez nich usług poprzez Serwis.&nbsp;</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Arial'}}><span style={{color: '#000000'}}>2. Administratorem danych osobowych zawartych w serwisie jest : “Fotografia”Piotr Świstak; NIP 5271323261; REGON 013019877; al. Prymasa Tysiąclecia, nr 99, lok. 56; 01-424 Warszawa</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Arial'}}><span style={{color: '#000000'}}>3. W trosce o bezpieczeństwo powierzonych nam danych opracowaliśmy wewnętrzne procedury i zalecenia, które mają zapobiec udostępnieniu danych osobom nieupoważnionym. Kontrolujemy ich wykonywanie i stale sprawdzamy ich zgodność z odpowiednimi aktami prawnymi - ustawą o ochronie danych osobowych, ustawą o świadczeniu usług drogą elektroniczną, a także wszelkiego rodzaju aktach wykonawczych i aktach prawa wspólnotowego.&nbsp;</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Arial'}}><span style={{color: '#000000'}}>4. Dane Osobowe przetwarzane są na podstawie zgody wyrażanej przez Użytkownika oraz w przypadkach, w których przepisy prawa upoważniają Administratora do przetwarzania danych osobowych na podstawie przepisów prawa lub w celu realizacji zawartej pomiędzy stronami umowy.</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Arial'}}><span style={{color: '#000000'}}>5. Serwis realizuje funkcje pozyskiwania informacji o użytkownikach i ich zachowaniach w następujący sposób:&nbsp;</span></span></span></p>
                <ol>
                    <li style={{listStyleType: 'lower-alpha'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Arial'}}><span style={{color: '#000000'}}>poprzez dobrowolnie wprowadzone w formularzach informacje</span></span></span></li>
                    <li style={{listStyleType: 'lower-alpha'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Arial'}}><span style={{color: '#000000'}}>poprzez gromadzenie plików “cookies” [patrz polityka plików “cookies”].&nbsp;</span></span></span></li>
                </ol>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Arial'}}><span style={{color: '#000000'}}>6. Serwis zbiera informacje dobrowolnie podane przez użytkownika.&nbsp;</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Arial'}}><span style={{color: '#000000'}}>7. dane podane w formularzu są przetwarzane w celu wynikającym z funkcji konkretnego formularza np. w celu dokonania procesu obsługi kontaktu informacyjnego</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Arial'}}><span style={{color: '#000000'}}>8. Dane osobowe pozostawione w serwisie nie zostaną sprzedane ani udostępnione osobom trzecim, zgodnie z przepisami Ustawy o ochronie danych osobowych.&nbsp;</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Arial'}}><span style={{color: '#000000'}}>9. Do danych zawartych w formularzu przysługuje wgląd osobie fizycznej, która je tam umieściła. Osoba ta ma również praw do modyfikacji i zaprzestania przetwarzania swoich danych w dowolnym momencie.&nbsp;</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Arial'}}><span style={{color: '#000000'}}>10. Zastrzegamy sobie prawo do zmiany w polityce ochrony prywatności serwisu, na które może wpłynąć rozwój technologii internetowej, ewentualne zmiany prawa w zakresie ochrony danych osobowych oraz rozwój naszego serwisu internetowego. O wszelkich zmianach będziemy informować w sposób widoczny i zrozumiały.</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Arial'}}><span style={{color: '#000000'}}>11. W Serwisie mogą pojawiać się linki do innych stron internetowych. Takie strony internetowe działają niezależnie od Serwisu i nie są w żaden sposób nadzorowane przez serwis www.wybierz.ovh Strony te mogą posiadać własne polityki dotyczące prywatności oraz regulaminy, z którymi zalecamy się zapoznać.</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Arial'}}><span style={{color: '#000000'}}>12. Podstawa prawna przetwarzania danych: w celu obsługi zakupów dokonywanych w serwisie - podstawą prawną przetwarzania jest niezbędność przetwarzania do wykonania umowy (art. 6 ust. 1 lit. b RODO);.</span></span></span></p>
                <p style={{textAlign: 'justify'}}><span style={{fontSize: '11pt'}}><span style={{fontFamily: 'Arial'}}><span style={{color: '#000000'}}>W razie wątpliwości co któregokolwiek z zapisów niniejszej polityki prywatności jesteśmy do dyspozycji - nasze dane znaleźć można w zakładce - KONTAKT.</span></span></span></p>
                <p>&nbsp;</p>
            </div>
        </>
    )
};

export default Polityka;
