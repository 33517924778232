import React, {FormEvent, useState} from 'react';
import { useHistory } from "react-router-dom";
import {LoginService} from "./Login.service";
import "./Login.scss";
import {Button, Divider, Input} from "antd";
import jsCookie from "js-cookie";

const Login = () => {
    const [userName, setUserName] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [loginError, setLoginError] = useState<boolean>(false);

    const history = useHistory();

    const handleLogin = async (e: FormEvent) => {
        e.preventDefault();
        setLoginError(false);

        try {
            const { data } = await LoginService.login({ userName, password });

            jsCookie.set('access_token', data.access_token, { domain: process.env.REACT_APP_DOMAIN });
            // jsCookie.set('access_token', data.access_token);

            history.push('/admin/dashboard');
        } catch (e) {
            setLoginError(true);
        }
    }

    return (
        <div className="admin without-sticky-menu">
            <div className="user-login-wrapper">
                <div className="login-card">
                    <div>
                        <div className="login-card__title login-card__color">ZALOGUJ SIĘ</div>
                        <div className="login-card__title">DO KONTA ADMINISTRATORA</div>
                    </div>

                    <Divider className="login-divider" />

                    <form onSubmit={handleLogin}>
                        <div className="login-form-wrapper">
                            <Input
                                size={"large"}
                                placeholder="Login"
                                className={`${loginError ? 'error' : ''}`}
                                value={userName}
                                onChange={e => {
                                    setLoginError(false);
                                    setUserName(e.currentTarget.value);
                                }}
                            />

                            <Input
                                size={"large"}
                                placeholder="Hasło"
                                type={"password"}
                                className={`password ${loginError ? 'error' : ''}`}
                                value={password}
                                onChange={e => {
                                    setLoginError(false);
                                    setPassword(e.currentTarget.value);
                                }}
                                onPressEnter={handleLogin}
                            />

                            <Button onClick={handleLogin} className="login-button" size={"large"} type={"primary"}>Zaloguj</Button>

                            {loginError && <div className="login-error">Logowanie nie powiodło się. Sprawdź nazwę użytkownika i hasło i spróbuj ponownie.</div>}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;