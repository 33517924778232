import axios from "axios";
import jsCookie from "js-cookie";

const adminInstance = axios.create();

adminInstance.interceptors.request.use(req => {
    req.headers['Authorization'] = `${jsCookie.get('access_token')}`

    return req
})

const userInstance = axios.create();

userInstance.interceptors.request.use(req => {
    req.headers['Authorization'] = `${jsCookie.get('user_access_token')}`

    return req
})

export { adminInstance, userInstance };
