import React from 'react';
import {Button} from "antd";
import {useHistory} from "react-router-dom";
import "./StickyMenu.css";
import jsCookie from "js-cookie";

const StickyMenu = () => {
    const history = useHistory();

    const logout = () => {
        jsCookie.remove('access_token', { domain: process.env.REACT_APP_DOMAIN });
        history.push('/admin/login');
    }

    return (
        <div className="sticky-menu">
            <Button type={"link"} onClick={logout} className="logout-button">Wyloguj</Button>
        </div>
    );
};

export default StickyMenu;