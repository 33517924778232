import {useEffect} from 'react';
import jwtDecode from "jwt-decode";
import {PasswordService} from "../../Password/Password.service";
import {useHistory, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../store/store";
import {setSession} from "../../store/sessionSlice";
import {restoreCart, setInitialCart} from "../../store/cartSlice";
import jsCookie from "js-cookie";

export const prepareSessionAndCart = async (dispatch: any, user_access_token: string) => {
    const { sessionId } = jwtDecode<{ sessionId: string }>(user_access_token);
    const { data: session } = await PasswordService.getSession(sessionId);
    dispatch(setSession({ session }));

    const savedCart = localStorage.getItem("cart");

    if (savedCart) {
        dispatch(restoreCart({ cart: JSON.parse(savedCart) }));
    } else {
        dispatch(setInitialCart({ session: session }));
    }
}

const useGetSession = () => {
    const { session: { session }, cart: { cart } } = useSelector((state: RootState) => state);
    const dispatch = useAppDispatch();
    const location = useLocation();

    const history = useHistory();

    useEffect(() => {
        (async () => {
            if (location.pathname === "/regulamin" || location.pathname === "/polityka-prywatnosci") return;

            if (!session) {
                const userAccessToken = jsCookie.get("user_access_token");

                if (userAccessToken) {
                    const { sessionId } = jwtDecode<{ sessionId: string }>(userAccessToken);
                    try {
                        const { data } = await PasswordService.getSession(sessionId);
                        dispatch(setSession({ session: data }));

                        await prepareSessionAndCart(dispatch, userAccessToken);
                    } catch (error: any) {
                        if (error.response.status === 401) {
                            history.push("/");
                        }
                    }
                } else {
                    history.push('/');
                    dispatch(restoreCart({ cart: null }));
                }
            }
        })();
    }, [dispatch, history, session]);
};

export default useGetSession;
