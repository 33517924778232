import React, {useEffect, useState} from 'react';
import {Link, useHistory, useRouteMatch} from 'react-router-dom';
import {AdminSessionService} from "./AdminSession.service";
import {Button, Checkbox, Divider, Input, Row, Select, Space, Upload} from "antd";
import {Field, Form} from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import {FieldArray} from 'react-final-form-arrays'
import Header from "../shared/Header/Header";
import Image from "./Image/Image";
import "./AdminSession.scss";
import PriceInput from "./PriceInput/PriceInput";
import {countProgress, refreshPageAfterAllImagesLoaded} from "./helpers";
import StickyMenu from "../shared/StickyMenu/StickyMenu";
import jsCookie from "js-cookie";

const { Dragger } = Upload;

const { TextArea } = Input;

interface PriceListEntry {
    format: string,
    price: string
}

interface AdditionalServiceEntry {
    label: string,
    price: string
}

export enum paymentMethods {
    PAYU = "PayU",
    COD = "Cash on Delivery",
}

export interface FormData {
    title: string;
    message: string;
    images: string[];
    password: string;
    priceList: PriceListEntry[];
    additionalServices: AdditionalServiceEntry[];
    paymentMethod: paymentMethods;
    showGroupPicker: boolean;
    groups: { name: string }[];
}

const EMPTY_PRICE_LIST_ENTRY = {
    format: '',
    price: ''
}

const INITIAL_FORM_DATA = {
    title: '',
    message: '',
    images: [],
    password: '',
    priceList: [EMPTY_PRICE_LIST_ENTRY],
    additionalServices: [],
    paymentMethod: paymentMethods.COD,
    showGroupPicker: false,
    groups: [{ name: "" }],
    showPhoneInput: false,
}

const prepareSessionData = (formData: FormData) => {
    const { showGroupPicker } = formData;

    if (showGroupPicker) {
        return formData;
    } else {
        return {
            ...formData,
            groups: [{ name: "" }],
        }
    }
}

const validate = (formData: FormData) => {
    const errors: { [key: string]: string } = {};

    if (!formData.title) errors.title = "Galeria musi mieć tytuł";
    if (!formData.password) errors.password = "Galeria musi mieć hasło";
    if (formData.priceList.length < 1) errors.priceList = "Galeria musi mieć co najmniej 1 format zdjęć";

    for (let i = 0; i < formData.priceList.length; i++) {
        if (!formData.priceList[i].price) {
            errors.priceListTotal = "Cena nie może być pusta";
        }

        if (!formData.priceList[i].format || !formData.priceList[i].price) {
            errors.priceListTotal = "Formaty i ceny nie mogą być puste";
            break;
        }

        if (isNaN(parseFloat(formData.priceList[i].price))) {
            errors.priceListTotal = "Ceny muszą być wartościami liczbowymi";
            break;
        }
    }

    for (let i = 0; i < formData.additionalServices?.length; i++) {
        if (!formData.additionalServices[i].price) {
            errors.additionalServicesTotal = "Cena nie może być pusta";
        }

        if (!formData.additionalServices[i].label || !formData.additionalServices[i].price) {
            errors.additionalServicesTotal = "Nazwy i ceny dodatkowych usług nie mogą być puste";
            break;
        }

        if (isNaN(parseFloat(formData.additionalServices[i].price))) {
            errors.additionalServicesTotal = "Ceny muszą być wartościami liczbowymi";
            break;
        }
    }

    if (formData.showGroupPicker) {
        if (formData.groups.length === 0) {
            errors.groups = "Dodaj co najmniej 1 grupę"
        }

        for (let i = 0; i < formData.groups.length; i++) {
            if (!formData.groups[i].name) {
                errors.singleGroup = "Nazwa grupy nie może być pusta";
                break;
            }
        }

    }

    return errors;
};

const AdminSession: React.FunctionComponent = () => {
    const match = useRouteMatch();
    const history = useHistory();
    const id = (match.params as { id: string }).id;
    const isEditMode = id !== 'new';
    const [initialEditValues, setInitialEditValues] = useState<any>(undefined);
    const [images, setImages] = useState<string[]>([]);
    const [progress, setProgress] = useState<string>('');

    const [isError, setIsError] = useState<boolean>(false);

    useEffect(() => {
        if (isEditMode) {
            (async () => {
                try {
                    const { data } = await AdminSessionService.getSession(id);
                    setInitialEditValues({
                        title: data.title,
                        message: data.message,
                        password: data.password,
                        priceList: data.priceList,
                        additionalServices: data.additionalServices,
                        paymentMethod: data.paymentMethod,
                        showGroupPicker: data.showGroupPicker,
                        groups: data.groups,
                        showPhoneInput: data.showPhoneInput,
                    });
                    setImages(data.images);
                } catch {
                    history.push('/admin/dashboard');
                }
            })();
        }
    }, [history, id, isEditMode]);

    const onSubmit = async (formData: FormData) => {
        try {
            if (isEditMode) {
                await AdminSessionService.updateSession(id, prepareSessionData(formData));
                history.push('/admin/dashboard');
            } else {
                const { data } = await AdminSessionService.saveNewSession(prepareSessionData(formData));
                history.push(`/admin/session/${data._id}`);
            }
        } catch {
            setIsError(true);
        }
    }

    if (isEditMode && !initialEditValues) return null;

    return (
        <div className="admin">
            <StickyMenu />

            <div className="content">
                <Link to="/admin/dashboard">Powrót do panelu</Link>

                <Header text={isEditMode ? "Edycja albumu" : "Tworzenie albumu"} />

                <Form
                    onSubmit={onSubmit}
                    mutators={{
                        ...arrayMutators
                    }}
                    validate={validate}
                    initialValues={isEditMode ? initialEditValues : INITIAL_FORM_DATA}
                    render={({ handleSubmit, dirty, errors, valid, form: { mutators: { push } }, values}) => (
                        <form onSubmit={handleSubmit}>
                            <div>
                                <Field name="title" render={({ input, meta }) => (
                                    <>
                                        <div className="form-item">
                                            <Space>
                                                <label htmlFor="title">Tytuł*</label>
                                                <Input id="title" {...input} className="title-input" />
                                            </Space>
                                        </div>
                                        { meta.error && meta.touched && <div className="error-message">Pole wymagane</div> }
                                    </>
                                )} />
                            </div>

                            <div>
                                <Space>

                                    <Field name="message" render={({ input, meta }) => (
                                        <>
                                            <div className="form-item">
                                                <Space>
                                                    <label htmlFor="message">Wiadomość</label>
                                                    <TextArea id="message" {...input} className="message-input" />
                                                </Space>
                                            </div>
                                        </>
                                    )} />
                                </Space>
                            </div>

                            <div>
                                <Field name="password" render={({ input, meta }) => (
                                    <>
                                        <div className="form-item">
                                            <Space>
                                                <label htmlFor="password">Hasło*</label>
                                                <Input type="text" id="password" {...input} />
                                            </Space>
                                        </div>
                                        { meta.error && meta.touched && <div>Pole wymagane</div> }
                                    </>
                                )} />
                            </div>

                            <Divider dashed />

                            <div>
                                <h3>Formaty:*</h3>
                                <FieldArray name="priceList">
                                    {({ fields }) =>
                                        fields.map((name, index) => (
                                            <div key={name} className="format-item">
                                                <Space size={"large"}>
                                                    <label>Format {index + 1}</label>
                                                    <Field
                                                        name={`${name}.format`}
                                                        render={({ input }) => (
                                                            <Input {...input} placeholder="Nazwa" />
                                                        )}
                                                    />
                                                    <Field
                                                        name={`${name}.price`}
                                                        render={({ input}) => (
                                                            <PriceInput price={input.value} onChange={input.onChange} />
                                                        )}
                                                    />
                                                    <Button type={"primary"} danger onClick={() => fields.remove(index)}>Usuń</Button>
                                                </Space>
                                            </div>
                                        ))
                                    }
                                </FieldArray>

                                <Button
                                    type={"primary"}
                                    onClick={() => push('priceList', EMPTY_PRICE_LIST_ENTRY)}
                                >
                                    Dodaj nowy format
                                </Button>
                            </div>

                            <Divider dashed />

                            <div>
                                <h3>Dodatkowe usługi:</h3>
                                <FieldArray name="additionalServices">
                                    {({ fields }) =>
                                        fields.map((name, index) => (
                                            <div key={name} className="format-item">
                                                <Space size={"large"}>
                                                    <Field
                                                        name={`${name}.label`}
                                                        render={({ input }) => (
                                                            <Input {...input} placeholder="Nazwa" />
                                                        )}
                                                    />
                                                    <Field
                                                        name={`${name}.price`}
                                                        render={({ input}) => (
                                                            <PriceInput price={input.value} onChange={input.onChange} />
                                                        )}
                                                    />
                                                    <Button type={"primary"} danger onClick={() => fields.remove(index)}>Usuń</Button>
                                                </Space>
                                            </div>
                                        ))
                                    }
                                </FieldArray>

                                <Button
                                    type="primary"
                                    onClick={() => push('additionalServices', { label: '', price: '' })}
                                >
                                    Dodaj nową usługę
                                </Button>
                            </div>

                            <Divider dashed />

                            <div>
                                <div>Opcje płatności:</div>
                                <div>
                                    <Field name="paymentMethod" render={({ meta, input }) => {
                                        return (
                                            <Select value={input.value} onChange={input.onChange}>
                                                <Select.Option value={paymentMethods.COD}>Przy odbiorze</Select.Option>
                                                <Select.Option value={paymentMethods.PAYU}>PayU</Select.Option>
                                            </Select>
                                        )
                                    }} />
                                </div>
                            </div>

                            <Divider dashed />

                            <div>
                                <div>
                                    Dodaj wybór grupy:
                                    <Field name="showGroupPicker" render={({ input }) => {
                                        return (
                                            <Checkbox onChange={input.onChange} checked={input.value} style={{ "marginLeft": "16px" }} />
                                        )
                                    }} />
                                </div>
                            </div>

                            {
                                values.showGroupPicker && (
                                    <div style={{ marginTop: "16px" }}>
                                        <FieldArray name="groups">
                                            {({ fields }) =>
                                                fields.map((name, index) => (
                                                    <div key={name} className="format-item">
                                                        <Space size={"large"}>
                                                            <label>Grupa {index + 1}</label>
                                                            <Field
                                                                name={`${name}.name`}
                                                                render={({ input }) => (
                                                                    <Input {...input} placeholder="Nazwa grupy" />
                                                                )}
                                                            />
                                                            <Button type={"primary"} danger onClick={() => fields.remove(index)}>Usuń grupę</Button>
                                                        </Space>
                                                    </div>
                                                ))
                                            }
                                        </FieldArray>

                                        <Button
                                            type={"primary"}
                                            onClick={() => push('groups', { name: "" })}
                                        >
                                            Dodaj nową grupę
                                        </Button>
                                    </div>
                                )
                            }

                            <Divider dashed />

                            <div>
                                <div>
                                    Pozwól wpisać dane kontaktowe:
                                    <Field name="showPhoneInput" render={({ input }) => {
                                        return (
                                            <Checkbox onChange={input.onChange} checked={input.value} style={{ "marginLeft": "16px" }} />
                                        )
                                    }} />
                                </div>
                            </div>

                            <Divider dashed />

                            {
                                !isEditMode && <div>Zapisz sesję, aby dodać zdjęcia</div>
                            }

                            {
                                isError && <div>Zapisanie galerii nie powiodło się, spróbuj ponownie!</div>
                            }

                            <div className="save-section">
                                {
                                    !dirty && <div>Nie wprowadzono żadnych zmian</div>
                                }
                                {
                                    dirty && (
                                        <div>
                                            <div>
                                                {errors && Object.values(errors).map(error => <div>{error}</div>)}
                                            </div>
                                            <Button type="primary" onClick={handleSubmit} disabled={!valid} className="save-button">Zapisz</Button>
                                        </div>
                                    )
                                }
                            </div>

                            <Row>
                            {
                                isEditMode && images.sort().map(imageUrl => <Image imageUrl={imageUrl} />)
                            }
                            </Row>

                            {
                                isEditMode && (
                                    <Dragger
                                        name="file"
                                        multiple
                                        action={`${process.env.REACT_APP_API}/admin/image/${id}`}
                                        headers={{
                                            authorization: jsCookie.get('access_token') || '',
                                        }}
                                        showUploadList={false}
                                        onChange={({ file, fileList, event }) => {
                                            const progress = countProgress(fileList);
                                            setProgress(progress);

                                            refreshPageAfterAllImagesLoaded(fileList);
                                        }}
                                    >
                                        { !!progress ? <div>{progress}</div> : <div>Dodaj zdjęcia</div> }
                                    </Dragger>
                                )
                            }
                        </form>
                    )}
                />
            </div>
        </div>
    );
};

export default AdminSession;
