import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import Password from "./Password/Password";
import Session from "./Session/Session";
import CartPage from "./CartPage/CartPage";
import Header from "./shared/Header/Header";
import './UserRouter.scss';
import Order from "./Order/Order";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "./store/store";
import {setSession} from "./store/sessionSlice";
import useGetSession from "./shared/useGetSession/useGetSession";
import {useSaveCartToLocalstorage} from "./shared/useSaveCartToLocalstorage/useSaveCartToLocalstorage";
import jsCookie from "js-cookie";
import {restoreCart} from "./store/cartSlice";
import Regulamin from "./Regulamin";
import Polityka from "./Polityka";

const UserRouter = () => {
    const { session: { session } } = useSelector((state: RootState) => state);
    const dispatch = useAppDispatch();

    const logout = () => {
        session && localStorage.removeItem("cart");
        dispatch(setSession({ session: null }));
        dispatch(restoreCart({ cart: null }));
        jsCookie.remove("user_access_token", { domain: process.env.REACT_APP_DOMAIN });
    };

    useGetSession();
    useSaveCartToLocalstorage();

    return (
        <>
            {
                session && <Header resetSession={logout} />
            }

            <div className={`content ${session ? "with-header" : ""}`}>
                <Switch>
                    <Route path="/regulamin"><Regulamin /></Route>
                    <Route path="/polityka-prywatnosci"><Polityka /></Route>
                    <Route path="/session"><Session /></Route>
                    <Route path="/cart"><CartPage /></Route>
                    <Route path="/order/:id"><Order /></Route>
                    <Route exact path="/"><Password /></Route>
                    <Redirect to="/" />
                </Switch>
            </div>
        </>
    );
}

export default UserRouter;