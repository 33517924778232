import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ISession} from "../../Admin/Dashboard/Dashboard.types";

interface SessionState {
    session: ISession | null;
}

const initialState: SessionState = { session: null };

export const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        setSession: (state, action: PayloadAction<{ session: ISession | null }>) => {
            state.session = action.payload.session;
        }
    }
});

export const { setSession } = sessionSlice.actions;

export default sessionSlice.reducer;