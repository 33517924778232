import { adminInstance } from '../helpers/fetch';
import { FormData } from "./AdminSession";

export class AdminSessionService {
    static getSession(id: string) {
        return adminInstance.get(`${process.env.REACT_APP_API}/admin/session/${id}`);
    }

    static updateSession(id: string, formData: FormData) {
        return adminInstance.post(`${process.env.REACT_APP_API}/admin/session/${id}`, formData);
    }

    static saveNewSession(formData: FormData) {
        return adminInstance.post(`${process.env.REACT_APP_API}/admin/session`, formData);
    }

    static deleteSession(id: string) {
        return adminInstance.delete(`${process.env.REACT_APP_API}/admin/session/${id}`);
    }
}
