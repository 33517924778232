import {UploadFile} from "antd/es/upload/interface";

export const refreshPageAfterAllImagesLoaded = (fileList: UploadFile[]) => {
    let allDone = true;

    fileList.forEach(singleFile => {
        if (singleFile.status !== "done") {
            allDone = false;
        }
    });

    if (allDone) {
        window.location.reload();
    }
}

export const countProgress = (fileList: UploadFile[]) => {
    let finished = 0;

    fileList.forEach(singleFile => {
        if (singleFile.status === "done") {
            finished += 1;
        }
    });

    if (finished === fileList.length) {
        window.location.reload();
    }

    return `${finished} / ${fileList.length}`;
}
