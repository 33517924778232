import React from 'react';
import {Divider} from "antd";
import "./Header.css";

interface Props {
    text: string;
}

const Header: React.FunctionComponent<Props> = ({ text }) => {
    return (
        <div>
            <div className="title">{text}</div>
            <Divider dashed />
        </div>
    );
};

export default Header;