import React from 'react';
import {Col} from "antd";
import './Image.scss';
import {imageWithToken} from "../../../shared/imageWithToken";

interface Props {
    imageUrl: string;
}

const Image: React.FunctionComponent<Props> = ({ imageUrl }) => {
    const imageName = imageUrl.split('/').slice(-1).pop();

    return (
        <Col span={4}>
            <div className="admin-image-outer-wrapper">
                <div className="admin-image-inner-wrapper">
                    <img src={imageWithToken(`${process.env.REACT_APP_API}/${imageUrl}`)} style={{ maxWidth: "100%" }} />
                </div>
                <div>{imageName}</div>
            </div>
        </Col>
    );
};

export default Image;
